import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import services from "@/router/services";

import Login from "@/views/system/Login";
import Error from "@/views/system/Error";
import Home from "@/views/system/Home";
import TMUI from "@/components/telemarketer/tmui/TMUI";
import DataForm from "@/components/database/data/form/DataForm";

function extractRoutes(e) {
  let nodes = [];
  e.forEach((element) => {
    if (element.path && element.path.startsWith("/")) {
      nodes.push(element);
    }
    if (element.children) {
      let childNodes = extractRoutes(element.children);
      if (childNodes.length > 0) {
        nodes = nodes.concat(childNodes);
      }
    }
  });
  return nodes;
}

function route(path, view, name, param) {
  return {
    name: name || view,
    path: path + (param ? "/" + param : ""),
    component: () => import(`@/views/${view}.vue`)
  };
}

Vue.use(VueRouter);

let routes = extractRoutes(services);

const router = new VueRouter({
  mode: "history",
  routes: routes
    .map((path) => route(path.path, path.view, path.name, path.param))
    .concat([
      {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { hideMenu: true, fillHeight: true }
      },
      {
        path: "/tmui",
        name: "TMUI",
        component: TMUI,
        meta: { hideMenu: true, fillHeight: true }
      },
      {
        path: "/database/form/:classId?",
        name: "DataForm",
        component: DataForm,
        meta: { hideMenu: true, fillHeight: true }
      },
      {
        path: "/unauthorized",
        name: "Unauthorized",
        component: Error,
        props: { title: "Error 401", subtitle: "You do not have to permission to enter this page" }
      },
      { path: "*", name: "NotFound", component: Error },
      { path: "/", name: "Home", component: Home }
    ]),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    // Allows programmatically setting the scrollBehavior, including x/y positions.
    /* i.e. this.$router.replace({
          params: {
            scrollBehavior: { x: 0, y: window.visualViewport.pageTop, behavior: 'smooth' }
          }
    */
    if (to.params.scrollBehavior) {
      return to.params.scrollBehavior;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (!store.getters["auth/isLoggedIn"]) {
    if (to.name !== "Login") {
      let nextObj = { path: "/login" };
      if (to.path != "/") {
        let queryParams = "";
        //If query params exist, append them to the redirect url
        if (to.query && Object.keys(to.query).length > 0) {
          let count = 0;
          for (let key in to.query) {
            if (count == 0) queryParams += `?${key}=${to.query[key]}`;
            else queryParams += `&${key}=${to.query[key]}`;
            count++;
          }
        }
        nextObj.query = { redirect: to.path + queryParams };
      }
      next(nextObj);
    }
  } else {
    if (to.name === "Login") {
      //redirect back to home if user already login
      next("/");
    } else {
      if (routes.some((route) => route.name === to.name) || routes.nonServices) {
        if (!store.getters["auth/getUserAccess"].includes(to.name)) {
          next("/unauthorized");
        }
      }
    }
  }

  next();
});

export default router;
