const clipboard = {
  namespaced: true,
  state: {
    clipboard: localStorage.getItem("clipboard") || "[]"
  },
  mutations: {
    setClipboard(state, value) {
      let cb = JSON.parse(state.clipboard);
      value.forEach((e) => {
        let attrId = e.ATTR_ID.match(/\d+/)[0];
        if (!cb.includes(attrId)) {
          cb.push(attrId);
        }
      });
      state.clipboard = JSON.stringify(cb);
      localStorage.setItem("clipboard", state.clipboard);
    },
    deleteClipboard(state, value) {
      let cb = JSON.parse(state.clipboard);
      const index = cb.indexOf(value);
      if (index >= 0) cb.splice(index, 1);
      state.clipboard = JSON.stringify(cb);
      localStorage.setItem("clipboard", state.clipboard);
    }
  },
  actions: {
    copy({ commit }, attrs) {
      commit("setClipboard", attrs);
    },
    delete({ commit }, attrId) {
      commit("deleteClipboard", attrId);
    }
  },
  getters: {
    getClipboard: (state) => {
      return JSON.parse(state.clipboard);
    }
  }
};

export default clipboard;
