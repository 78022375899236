import services from "@/router/services";

const auth = {
  namespaced: true,
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user: JSON.parse(localStorage.getItem("user")) || {},
    menu: JSON.parse(localStorage.getItem("menu")) || {},
    apihost: JSON.parse(localStorage.getItem("apihost")) || {}
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, payload) {
      state.status = "success";
      state.token = payload.token;
      state.user = payload.user;
      state.menu = payload.menu;
      state.apihost = payload.apihost;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.user = "";
    }
  },
  actions: {
    login({ commit }, param) {
      commit("auth_request");
      return window
        .axios({ url: "/auth/login", data: param, method: "POST" })
        .then((response) => {
          if (response.data.error) {
            commit("auth_error");
            return Promise.reject(response.data.error);
          } else {
            const { token, user, apihost } = response.data;
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("apihost", JSON.stringify(apihost));

            let menu = getAllowedNode(services, parseInt(user.roleid));

            localStorage.setItem("menu", JSON.stringify(menu));
            let payload = response.data;
            payload.menu = menu;

            window.axios.defaults.headers.common["Authorization"] = token;
            commit("auth_success", payload);
            return true;
          }
        })
        .catch((err) => {
          commit("auth_error");
          return Promise.reject(err);
        });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        clearLocalStorage();
        delete window.axios.defaults.headers.common["Authorization"];
        resolve();
      });
    }
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    getMenu: (state) => state.menu,
    getApiHost: (state) => state.apihost,
    getUserAccess: (state) => {
      return extractAllowedPath(state.menu);
    }
  }
};

// retrieve all nodes available to specific role
function getAllowedNode(node, roleId, parent = false) {
  let newNode = [];

  for (let key in node) {
    let childNode = node[key];

    if (childNode.dev_only && process.env.VUE_APP_ENV == "prod") continue;

    // inherit allow role from parent if does not exist
    if (parent?.allow_role && !childNode.allow_role) {
      childNode.allow_role = parent.allow_role;
    }

    if (
      (!childNode.allow_role || !childNode.allow_role.includes(roleId)) &&
      ![1, 3].includes(roleId)
    ) {
      continue;
    }

    if (childNode.children) {
      let newChildren = getAllowedNode(childNode.children, roleId, childNode);
      if (newChildren.length > 0) {
        childNode.children = newChildren;
        newNode.push(childNode);
      }
    } else {
      newNode.push(childNode);
    }
  }

  // logic for top level node
  if (!parent) {
    if (roleId == 1) {
      newNode = newNode.filter((service) => service.name != "Telemarketer");
    } else if (roleId == 3) {
      newNode = newNode.filter((service) => service.name == "Telemarketer")[0].children;
    }
  }

  return newNode;
}

// needed to check for user path permission
function extractAllowedPath(e) {
  let paths = [];
  e.forEach((element) => {
    if (element.path && !element.apihost) {
      paths.push(element.name);
    }
    if (element.children) {
      let childPaths = extractAllowedPath(element.children);
      if (childPaths.length > 0) {
        paths = paths.concat(childPaths);
      }
    }
  });

  return paths;
}

function clearLocalStorage() {
  let storedItem = ["token", "user", "menu", "verifyleads", "clipboard"];
  storedItem.forEach((e) => {
    localStorage.removeItem(e);
  });
}

export default auth;
