const getDefaultState = () => ({
  isEditing: false,
  previewParamDialog: false,
  spot: {
    spot_id: null,
    spot: null,
    description: null,
    create_date: null,
    last_modified: null,
    model: null,
    html: null,
    text: null,
    xml: null,
    json: null,
    test_params: null,
    nls: null,
    web: null,
    spot_group_id: null,
    template_spot_id: null,
    active: `1`,
    type: 'template', // Must set initial value to avoid new spot forms being immediately set as isEditing=true on page load 
    templateModel: null,
    templateXml: null,
    templateJson: null,
    templateHtml: null,
    templateText: null,
    enable_backfill: `1`
  },
  spotLoading: false
})

const spot = {
  namespaced: true,

  // Done this way to easily reset the state
  state: {
    ...getDefaultState(),
    // Excluding the following from default state so that they don't get reset with resetState()
    hideDisabled: true,
    groupSpots: false,
    openedSpotGroups: {}
  },

  mutations: {
    setGroupSpots(state, groupSpots) {
      state.groupSpots = groupSpots;
    },
    setHideDisabled(state, hideDisabled) {
      state.hideDisabled = hideDisabled;
    },
    setIsEditing(state, isEditing) {
      state.isEditing = isEditing;
    },
    setOpenedSpotGroups(state, openedSpotGroups) {
      state.openedSpotGroups = {...openedSpotGroups};
    },
    setPreviewParamDialog(state, previewParamDialog) {
      state.previewParamDialog = previewParamDialog;
    },
    setSelectedPreviewParameters(state, selectedPreviewParameters) {
      state.selectedPreviewParameters = selectedPreviewParameters;
    },
    setSpot(state, spot) {
      state.spot = spot;
    },
    setSpotLoading(state, spotLoading) {
      state.spotLoading = spotLoading;
    },
    setOpenedSpotGroup(state, payload) {
      state.openedSpotGroups[payload.group] = payload.value;
    },
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState())
    }
  },

  actions: {
    updateOpenedSpotGroup({ commit }, payload) {
      console.log('updateOpnedSpotGroup');
      console.log(payload);
      commit("setOpenedSpotGroup", payload);
    },
    updateOpenedSpotGroups({ commit }, openedSpotGroups) {
      commit("setOpenedSpotGroups", openedSpotGroups);
    },
  }
};
  
export default spot;
  