const backfillPools = {
  namespaced: true,

  state: {
    pools: [],
    slotDialog: false,
    loading: true,
    countrySearch: [],
    editedFeedIndex: -1,
    editedIndex: -1,
    editedItem: {
      pool_id: 0,
      name: "",
      type: "",
      percentage: 0,
      enabled: 0,
      locked: 0,
      spotData: [],
      inactiveItems: 0,
      items: 0,
      removedData: [],
      typeData: [],
      origTypeData: []
    },
    //edit pool form
    editPool: false,
    //edit percent form
    editPercent: false,
    defaultFeed: {
      assetCount: null,
      countriesAdded: new Set(),
      countriesRemoved: new Set(),
      defaultCT: null,
      defaultCTA: null,
      defaultOrg: null,
      feedUrl: "",
      feedId: null,
      geo_inclusive: 1,
      lastIngestionDate: null,
      mapping: {},
      override: false,
      selectedCountries: [],
      targeting_notes: "",
    },
    defaultItem: {
      pool_id: 0,
      name: "",
      type: "",
      percentage: 0,
      enabled: 0,
      locked: 0,
      spotData: [],
      inactiveItems: 0,
      items: 0,
      typeData: []
    },
    feedUrlDialog: false,
    geoCacheKey: "",
    geoDialog: false,
    historyLog: [],
    historyLogLoaded: false,
    initialPercent: 0,
    isEditing: false,
    lockedPercent: 0,
    mappingDialog: false,
    newUnsavedPools: [],
    origPools: [],
    readOnlyPools: false,
    resetFeed: {
      assetCount: null,
      countriesAdded: new Set(),
      countriesRemoved: new Set(),
      defaultCT: null,
      defaultCTA: null,
      defaultOrg: null,
      feedUrl: "",
      feedId: null,
      geo_inclusive: 1,
      lastIngestionDate: null,
      mapping: {},
      override: false,
      selectedCountries: [],
      targeting_notes: "",
    },
    startingItemCount: 0,
    tabs: [],
    unsavedFeeds: false,
    unsavedGeo: false,
    unsavedMapping: false,
    userActivity: []
  },

  mutations: {
    addGeoCacheKey(state, geoCacheKey){
      state.geoCacheKey += geoCacheKey;
    },
    addPool(state, pool) {
      state.pools.unshift(pool);
    },
    resetDefaultFeed(state){
      state.defaultFeed = JSON.parse(JSON.stringify(state.resetFeed));
    },
    resetGeoCacheKey(state){
      state.geoCacheKey = "";
    },
    resetUserActivity(state){
      state.userActivity = [];
    },
    setCountrySearch(state, countrySearch){
      state.countrySearch = countrySearch;
    },
    setEditedFeedIndex(state, editedFeedIndex) {
      state.editedFeedIndex = editedFeedIndex;
    },
    setEditedIndex(state, editedIndex) {
      state.editedIndex = editedIndex;
    },
    setEditedItem(state, editedItem) {
      state.editedIndex = editedItem;
    },
    setEditPool(state, editPool) {
      state.editPool = editPool;
    },
    setEditPercent(state, editPercent) {
      state.editPercent = editPercent;
    },
    setFeedUrlDialog(state, feedUrlDialog) {
      state.feedUrlDialog = feedUrlDialog;
    },
    setGeoDialog(state, geoDialog) {
      state.geoDialog = geoDialog;
    },
    setHistoryLog(state, historyLog) {
      state.historyLog = historyLog;
    },
    setHistoryLogLoaded(state, historyLogLoaded) {
      state.historyLogLoaded = historyLogLoaded;
    },
    setIsEditing(state, isEditing) {
      state.isEditing = isEditing;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setMappingDialog(state, mappingDialog){
      state.mappingDialog = mappingDialog;
    },
    setLockedPercent(state, lockedPercent) {
      state.lockedPercent = lockedPercent;
    },
    setPools(state, pools) {
      state.pools = pools;
    },
    setReadOnlyPools(state, readOnlyPools) {
      state.readOnlyPools = readOnlyPools;
    },
    setSlotDialog(state, slotDialog) {
      state.slotDialog = slotDialog;
    },
    setTabs(state, tabs){
      state.tabs = tabs;
    },
    setUnsavedFeeds(state, unsavedFeeds){
      state.unsavedFeeds = unsavedFeeds;
    },
    setUnsavedGeo(state, unsavedGeo){
      state.unsavedGeo = unsavedGeo;
    },
    setUnsavedMapping(state, unsavedMapping){
      state.unsavedMapping = unsavedMapping;
    }
  },

  actions: {
    clearNewUnsavedPools(context){
      context.state.newUnsavedPools = [];
    },
    getBackfillPools(context, { spotId }) {
      let spotPoolIndex = [];

      context.commit("setLoading", true);
      return window.axios
        .get("/medusa/pools/backfill_pools", {
          params: {
            spotId: spotId !== "create" ? spotId : null
          }
        })
        .then((res) => {
          for (let [index, row] of res.data.entries()) {
            //For Spot level
            if (spotId) {
              //Prepare To Remove Pool if disabled at Master level
              if (row["enabled"] == 0) {
                spotPoolIndex.push(index);
                continue;
              }
              //Set Disabled Pools at spot level
              else if (row["spotData"]) {
                row.spotData.filter(function(a) {
                  if (a.spot_id == spotId) {
                    row["enabled"] = 0;
                    return;
                  }
                });
              }
            }

            //Change DB's value from string to number
            row["enabled"] = Number(row["enabled"]);
            row["locked"] = Number(row["locked"]);
            //Temp Values
            if(!row['typeData']) row['typeData'] = [];
            row["typeSet"] = row["type"] ? true : false;
            row["typeDataAltered"] = false;
            row["removedData"] = [];

            let items = 0;
            if (row["type"] == "feed") {
              if (row["typeData"].length > 0) {
                for (let data of row["typeData"]) {
                  items += Number(data["assetCount"]);
                  data["override"] = Number(data["override"]);
                  data["countriesAdded"] = new Set();
                  data["countriesRemoved"] = new Set();
                  data['geo_inclusive_changed'] = false;

                  if(!data['selectedCountries']) data['selectedCountries'] = [];
                  if(!data['geo_inclusive']) data['geo_inclusive'] = 1;
                  else data['geo_inclusive'] = Number(data['geo_inclusive']);
                }
              }
              row["items"] = items;
            } else if (row["type"] == "doc") {
              if (row["typeData"].length > 0) {
                //Loop Through Doc TypeData to count active and inactive docs
                let activeCount = 0;
                let inactiveCount = 0;

                for (let data of row["typeData"]) {
                  if (data.DocStatusId == 1) {
                    data.Active = true;
                    activeCount += 1;
                  } else {
                    data.Active = false;
                    inactiveCount += 1;
                  }
                }
                items = activeCount;
                row["inactiveItems"] = inactiveCount;
              }
              row["items"] = items > 0 ? items : 0;
            } else row["items"] = 0;
          }
          //Remove Master pools that are disabled for spot level view
          if (spotPoolIndex.length > 0) {
            for (let index of spotPoolIndex.reverse()) {
              res.data.splice(index, 1);
            }
          }

          context.commit("setLoading", false);
          return res.data;
        });
    },
    editBackfillPool(context, { index, item }) {
      context.state.editedIndex = index;
      context.state.editedItem = Object.assign({}, item);
      context.state.initialPercent = Number(JSON.parse(JSON.stringify(item.percentage)));
      context.commit("setSlotDialog", true);

      context.state.origEditedItem = JSON.parse(JSON.stringify(context.state.editedItem));
      context.state.startingItemCount = context.state.origEditedItem.items;
    },
    //Occurs When Master Pool Edit Form Is Saved
    resetDefaultItem(context) {
      context.state.editedItem = Object.assign({}, context.state.defaultItem);
      context.commit("setEditedIndex", -1);
      context.state.origEditedItem = [];
    },
    //Occurs When Master Pool Edit Form Is Canceled to reset the TypeData
    resetEditedItem(context, { index }) {
      if (context.state.pools[index]) {
        context.state.pools[index].typeData = context.state.origEditedItem.typeData;
        context.state.pools[index].removedData = [];
        context.state.origEditedItem.items = context.state.startingItemCount;
      }
      context.dispatch("resetDefaultItem");
    },
    resetOrigPools(context) {
      context.state.origPools = JSON.parse(JSON.stringify(context.state.pools));
    }
  }
};

export default backfillPools;