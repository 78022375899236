<template>
  <v-container fluid> </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    this.doRedirect();
  },
  methods: {
    doRedirect() {
      if (this.$store.state.auth.user.rolename == "telemarketer") {
        this.$router.push({ name: "Dashboard" });
      }
    }
  }
};
</script>
