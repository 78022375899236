const lineorder = {
  namespaced: true,

  state: {
    customQuestionDialog: false,
    customAnswerDialog: false,
  },

  mutations: {
    setCustomAnswerDialog(state, customAnswerDialog) {
      state.customAnswerDialog = customAnswerDialog;
    },
    setCustomQuestionDialog(state, customQuestionDialog){
      state.customQuestionDialog = customQuestionDialog;
    },
  },

  actions: {}
};

export default lineorder;