export default {
  methods: {
    openTMUI(companyId, contactId, ids, options = {}) {
      const height = 1087;
      const width = 1145;
      let formType = ids.classId ? `database/form/${ids.classId}` : "tmui";
      let url = `/${formType}?companyId=${companyId}&contactId=${contactId}`;
      for (const [key, value] of Object.entries({ ...ids, ...options })) {
        if (value && key != "classId") url += `&${key}=${value}`;
      }
      return window.open(
        url,
        "tmui",
        "width=" +
          width +
          ",height=" +
          height +
          ",resizable=1,scrollbars=1,top=" +
          (screen.availHeight - height) / 2 +
          ",left=" +
          (screen.availWidth - width) / 2
      );
    },
    /**
     * Sort an array of objects by property
     *
     * @param property, property to sort on
     */
    dynamicSort(property) {
      var sortOrder = 1;

      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }

      return function(a, b) {
        if (sortOrder == -1) {
          return b[property].localeCompare(a[property]);
        } else {
          return a[property].localeCompare(b[property]);
        }
      };
    },
    /**
     * Send desktop notification
     *
     * @param {number} msg Message to send.
     * @param {boolean} onlyIfHidden send notification only if tab is inactive.
     * @return {Notification} Notification object.
     */
    sendNotification(msg, onlyIfHidden = false) {
      if (onlyIfHidden && !document.hidden) {
        return false;
      }
      let notification;
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        notification = new Notification(msg);
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function(permission) {
          if (permission === "granted") {
            notification = new Notification(msg);
          }
        });
      }
      // default to focus tab on click
      if (notification && onlyIfHidden) {
        notification.onclick = function() {
          window.focus();
        };
      }
      return notification;
    }
  }
};
