const medusaCampaigns = {
    namespaced: true,
  
    state: {
      activeFilters: { 
        status: null,
        countries: null,
        promote: null
      },
      listLoading: false,
      isEditingLO: false,
      itemsPerPage: 20,
      // Holds documents whose promotions have been changed
      // { $lineorderNumber => [...{ $docId => {promo=>$newPromo, index=>$loDocTableItemsMapIndex} }] }
      editedLODocs: {},
      // Holds lazy loaded lineorder document data
      // { $lineorderNumber => [...{*document table data*}] }
      loDocTableItemsMap: {}
    },
  
    mutations: {
      setActiveFilters(state, activeFilters) {
        state.activeFilters = activeFilters;
      },
      setListLoading(state, listLoading) {
        state.listLoading = listLoading;
      },
      setIsEditingLO(state, isEditingLO) {
        state.isEditingLO = isEditingLO;
      },
      setItemsPerPage(state, itemsPerPage) {
        state.itemsPerPage = itemsPerPage;
      }
    },
    actions: {
      purgeMedusaCache(context, {cacheName, keys, keysSupplement}) {
        window.axios
          .get("medusa/purgeMedusaCache", {
            params: {
              cache: cacheName,
              keys: keys,
              keysSupplement: keysSupplement
            }
          })
          .then((res) => {
            let color, msg;
            if (res.data.success) {
              color = "success";
              msg = "Cache purged: " + res.data.success;
            } else {
              color = "error";
              msg = "Cache failed to purge: " + res.data.error;
            }
            // Display success/error message
            this.commit("sendAlert", {
              msg: msg,
              color: color
            });
          });
      },
      resetDocPromotions(context) {
        for (const [lon, docs] of Object.entries(context.state.editedLODocs)) {
          // Iterates over table items rather than using .findIndex() for efficiency
          for (const item of context.state.loDocTableItemsMap[lon]) {
            // If item's promote has been changed, revert it and remove doc from editedLODocs object
            if (item.docId in docs) {
              item.promote = item.promote=='1' ? '0' : '1';
              delete docs[item.docId];
            }
            // If there are no remaining docs to be reverted, stop iterating
            if (Object.keys(docs).length == 0) break;
          }
        }
        // Reset editingLODocs object once all LO docs' promotions have been reverted
        context.dispatch("resetEditedLODocs");
      },
      resetEditedLODocs(context) {
        context.state.editedLODocs = {};
      }
    }
  };
  
  export default medusaCampaigns;