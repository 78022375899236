<template>
  <div class="mt-5">
    <span class="text-h3 primary--text">{{ title }}</span>
    <p>{{ subtitle }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Error 404"
    },
    subtitle: {
      type: String,
      default: "The page you were looking for does not exist!"
    }
  }
};
</script>
