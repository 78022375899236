const services = [
  {
    name: "Telemarketer",
    icon: null,
    path: null,
    children: [
      {
        name: "Dashboard",
        icon: "mdi-view-dashboard",
        path: "/telemarketer/dashboard",
        view: "telemarketer/Dashboard"
      },
      {
        name: "Timesheet",
        icon: "mdi-briefcase-clock-outline",
        path: "/telemarketer/timesheet",
        view: "telemarketer/Timesheet"
      },
      {
        name: "Call List",
        icon: "mdi-phone-log",
        path: "/telemarketer/calllist",
        view: "telemarketer/CallList"
      },
      {
        name: "Schedule Call Back List",
        icon: "mdi-calendar-clock",
        path: "/telemarketer/schedule-callback-list",
        view: "telemarketer/ScheduleCallBackList"
      },
      {
        name: "Message History",
        icon: "mdi-message-text-clock",
        path: "/telemarketer/message-history",
        view: "telemarketer/MessageHistory"
      },
      {
        name: "Request List",
        icon: "mdi-human-greeting",
        path: "/telemarketer/request-list",
        view: "telemarketer/RequestList"
      }
    ]
  },
  {
    name: "TR Directory",
    icon: "mdi-folder-text-outline",
    path: "/directory/",
    dev_only: 1,
    allow_role: [2],
    children: [
      {
        name: "Document/Asset Management",
        icon: null,
        path: "/directory/document",
        view: "directory/Document",
        param: ":id?"
      },
      {
        name: "Online Campaign Management",
        icon: null,
        path: "/directory/lineorder",
        view: "directory/LineOrder",
        param: ":id?"
      }
    ]
  },
  {
    name: "LNP",
    icon: "mdi-newspaper-variant-multiple-outline",
    path: "/lnp",
    view: "lnp/LNP",
    param: ":classId?/:campaignId?/:tab?/:id?",
    dev_only: 1,
    allow_role: [2]
  },
  {
    name: "CCMS",
    icon: "mdi-card-account-phone-outline",
    dev_only: 1,
    allow_role: [2],
    path: "/ccms/",
    children: [
      {
        name: "IO",
        path: "/ccms/io",
        view: "ccms/campaign/IO",
        param: ":id?"
      },
      {
        name: "Project",
        path: "/ccms/project",
        view: "ccms/campaign/Project",
        param: ":id?"
      },
      {
        name: "Agents",
        path: "/ccms/agents",
        view: "ccms/campaign/Agents",
        param: ":id?"
      },
      {
        name: "Time Sheet",
        path: "/ccms/timesheet",
        view: "ccms/campaign/Timesheet",
        param: ":id?"
      },
      {
        name: "Agent Assignment & Pay",
        path: "/ccms/assignment",
        view: "ccms/campaign/Assignment",
        param: ":id?"
      },
      {
        name: "Project Expense",
        path: "/ccms/expenses",
        view: "ccms/campaign/Expense",
        param: ":id?"
      },
      {
        name: "Holiday Tracker",
        path: "/ccms/holiday",
        view: "ccms/holiday/Holiday",
        param: ":id?"
      },
      {
        name: "Report",
        path: "/ccms/report",
        view: "ccms/report/Report",
        param: ":id?"
      }
    ]
  },
  {
    name: "Database",
    icon: "mdi-database-outline",
    path: "/database/",
    dev_only: 1,
    children: [
      {
        name: "Design",
        icon: null,
        path: "/database/design",
        view: "database/Design",
        param: ":classId?"
      },
      {
        name: "Data",
        icon: null,
        path: "/database/data",
        view: "database/Data",
        param: ":classId?"
      }
    ]
  },
  {
    name: "Medusa",
    icon: null,
    custom_icon: {
      svg_path: "/medusa-icon.svg",
      height: "24px",
      width: "24px"
    },
    path: "/medusa/",
    allow_role: [2],
    children: [
      {
        name: "Campaign Management",
        icon: null,
        path: "/medusa/campaign",
        view: "medusa/MedusaCampaign",
        param: ":lineorderNumber?"
      },
      {
        name: "Backfill Pools",
        icon: null,
        path: "/medusa/backfillpools",
        view: "medusa/BackfillPools"
      },
      {
        name: "Spot Editor",
        icon: null,
        path: "/medusa/spot",
        view: "medusa/Spot",
        param: ":spotId?",
        allow_role: [2]
      },
      {
        name: "Spot Groups",
        icon: null,
        path: "/medusa/spotGroups",
        view: "medusa/SpotGroups",
        allow_role: [1]
      }
    ]
  },
  {
    name: "Help",
    icon: "mdi-help",
    path: "/medusa/",
    allow_role: [2],
    children: [
      {
        name: "Raise Ticket",
        icon: null,
        path: "https://technologyadvice.atlassian.net/servicedesk/customer/portal/7"
      }
    ]
  }
];

export default services;
