import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./modules/auth";
import ccms from "./modules/ccms";
import clipboard from "./modules/clipboard";
import backfillPools from "./modules/backfillPools";
import lineorder from "./modules/lineorder";
import spot from "./modules/spot";
import spotPreview from "./modules/spotPreview";
import medusaCampaigns from "./modules/medusaCampaigns";
import medusaCopy from "./modules/medusaCopy";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        'medusaCampaigns.activeFilters',
        'medusaCampaigns.itemsPerPage',
        'spot.hideDisabled',
        'spot.groupSpots',
        'spot.openedSpotGroups',
        'spotPreview'
      ],
    }),
  ],
  modules: {
    auth,
    ccms,
    backfillPools,
    lineorder,
    spot,
    spotPreview,
    medusaCampaigns,
    clipboard,
    medusaCopy
  },
  state: {
    sidebar: false,
    routesAdded: false,
    alert: {
      show: false,
      msg: "",
      color: "info",
      position: "bottom"
    }
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebar = !state.sidebar;
    },
    confirmRoutesAdded(state) {
      state.routesAdded = true;
    },
    sendAlert(state, alert) {
      state.alert.msg = alert.msg;
      state.alert.color = alert.color || "info";
      state.alert.position = alert.position || "bottom";
      state.alert.show = true;
    },
    offAlert(state) {
      state.alert.show = false;
    }
  }
});
