const spotPreview = {
    namespaced: true,

    // Done this way to easily reset the state
    state: {
        format: "json",
        // Initialized with blank selection so purpose is obvious to user
        selectedParameters: [{ parameter: "", value: "" }],
        previewType: null,
    },

    mutations: {
        setFormat(state, format) {
            state.format = format;
        },
        setSelectedParameters(state, selectedParameters) {
            state.selectedParameters = selectedParameters;
        },
        setPreviewType(state, previewType) {
            state.previewType = previewType;
        }
    },

    actions: {
        
    }
};

export default spotPreview;
    