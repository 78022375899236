const medusaCopy = {
  namespaced: true,

  state: {
    // Object must be initialized with properties, or computed properties dependant on property
    // changes will never update
    medusaCopy: {
      doc_id: null,
      country_limitation: null,
      html_title: null,
      html_description: null,
      html_dedicated_send: null,
      state: "publish",
      subject: null,
      text_title: null,
      text_description: null,
      text_dedicated_send: null,
      thumbnail_id: null
    },
    medusaCopyDialog: false,
    previewParams: {
      docId: "docId",
      htmlTitle: "htmlTitle",
      htmlDescription: "htmlDescription",
      htmlDedicatedSend: "htmlDedicatedSend",
      textTitle: "textTitle",
      textDescription: "textDescription",
      thumbnail: "thumbnail",
      action: "action"
    },
    showPreview: false
  },

  mutations: {
    setMedusaCopy(state, medusaCopy) {
      state.medusaCopy = medusaCopy;
    },
    setMedusaCopyDialog(state, medusaCopyDialog) {
      state.medusaCopyDialog = medusaCopyDialog;
    },
    setShowPreview(state, showPreview) {
      state.showPreview = showPreview;
    }
  },

  actions: {}
};

export default medusaCopy;