const ccms = {
  namespaced: true,
  state: {
    activeProject: null,
    activeCall: localStorage.getItem("activeCall") || "",
    scheduleCnt: null
  },
  mutations: {
    setActiveProject(state, ccmsProjectId) {
      state.activeProject = ccmsProjectId;
    },
    setActiveCall(state, userLogId) {
      if (userLogId > 0) {
        localStorage.setItem("activeCall", userLogId);
      } else {
        localStorage.removeItem("activeCall");
      }
      state.activeCall = userLogId;
    },
    setScheduleCount(state, count) {
      state.scheduleCnt = count;
    }
  },
  actions: {
    startWork({ commit }, ccmsProjectId) {
      return window.axios
        .post("/telemarketer/timesheet/start", {
          ccmsProjectId: ccmsProjectId
        })
        .then((res) => {
          commit("setActiveProject", ccmsProjectId);
          return res.data;
        });
    },
    endWork({ state, commit }, lunchHour) {
      return window.axios
        .put("/telemarketer/timesheet/finish", {
          ccmsProjectId: state.activeProject,
          lunchHour: lunchHour
        })
        .then((res) => {
          if (!res.data.error) {
            commit("setActiveProject", undefined);
          }
          return res.data;
        });
    },
    makeCall({ commit }, params) {
      return window.axios.post("/telemarketer/calllist/tmui/dial", params).then((res) => {
        commit("setActiveCall", res.data.USER_LOG_ID);
        return res.data.USER_LOG_ID;
      });
    },
    endCall({ commit }, params) {
      return window.axios.post("/telemarketer/calllist/tmui/endcall", params).then((res) => {
        commit("setActiveCall", null);
        return res.data.USER_LOG_ID;
      });
    },
    checkSchedule({ state, commit }, cnt) {
      if (cnt > -1) {
        commit("setScheduleCount", cnt);
        return Promise.resolve(cnt);
      } else {
        return window.axios
          .get("/telemarketer/schedule/check", {
            params: {
              ccmsId: state.activeProject
            }
          })
          .then((res) => {
            let cnt = res.data.count ?? 0;
            commit("setScheduleCount", cnt);
            return cnt;
          });
      }
    }
  },
  getters: {}
};

export default ccms;
