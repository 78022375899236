"use strict";

import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: process.env.VUE_APP_ROOT_API
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    const token = store.state.auth.token;

    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    if (typeof response.data == "string" && response.data.startsWith("<")) {
      store.commit("sendAlert", {
        msg: "Possible error from " + response.request.responseURL,
        color: "error"
      });
      return Promise.reject("DB error");
    }

    return response;
  },
  function(error) {
    if (error.response && error.response.status === 401) {
      if (store.getters["auth/isLoggedIn"]) {
        store.dispatch("auth/logout").then(() => {
          router.push({
            path: "/login",
            query: { redirect: router.currentRoute.path, expired: 1 }
          });
        });
      }
    } else if (axios.isCancel(error)) {
      //cancelled
    } else {
      store.commit("sendAlert", { msg: "Error connecting to API.", color: "error" });
    }

    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
