<template>
  <div style="display: contents">
    <v-row align="center" justify="center">
      <v-col cols="12" style="max-width: 450px; min-width: 450px">
        <v-form v-model="valid" ref="form">
          <v-card class="elevation-12">
            <v-card-text v-if="authenticating">
              <v-progress-linear indeterminate color="cyan"></v-progress-linear>
              <div class="py-2">Authenticating in progress...</div>
            </v-card-text>
            <template v-else>
              <v-card-text>
                <v-text-field
                  required
                  label="Username"
                  v-model="username"
                  prepend-icon="person"
                  type="text"
                  :rules="[(v) => !!v || 'Username is required']"
                />
                <v-text-field
                  required
                  label="Password"
                  v-model="password"
                  prepend-icon="lock"
                  type="password"
                  :rules="[(v) => !!v || 'Password is required']"
                  @keyup.enter="login()"
                />
              </v-card-text>
              <v-col cols="12" class="pt-0">
                <v-btn @click="login()" color="primary" style="width: 100%">Login</v-btn>
              </v-col>
              <v-container class="textDivider">
                <v-row align="center">
                  <v-divider></v-divider>
                  <div>or</div>
                  <v-divider></v-divider>
                </v-row>
              </v-container>

              <v-col cols="12" class="pt-0">
                <v-btn
                  class="white--text"
                  style="width: 100%"
                  :disabled="!gcp.url"
                  :loading="gcp.loading"
                  :href="gcp.url"
                  color="rgb(0, 125, 193)"
                  >Continue with Google</v-btn
                >
              </v-col>
              <template v-if="upsSession.token">
                <v-col cols="12" class="pt-0">
                  <v-btn style="width: 100%" @click="login(upsSession.token)" color="success"
                    >Resume UPS session ({{ upsSession.username }})</v-btn
                  >
                </v-col>
              </template>
            </template>
          </v-card>
          <v-alert v-if="error" dense type="error" class="mt-4">
            {{ error }}
          </v-alert>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: "",
      gcp: {
        loading: false,
        url: undefined
      },
      authenticating: false,
      valid: false,
      username: "",
      password: "",
      upsSession: {
        username: null,
        token: null
      }
    };
  },
  methods: {
    getGCPUrl() {
      this.gcp.loading = true;
      this.$axios
        .get("/auth/get_gcp_url", {
          params: {
            redir: this.$route.query.redirect
          }
        })
        .then((res) => {
          if (res.data) {
            this.gcp.url = res.data;
          }
        })
        .finally(() => {
          this.gcp.loading = false;
        });
    },
    login(token) {
      if (token || this.$route.query.code || this.$refs.form.validate()) {
        this.authenticating = true;
        this.error = "";
        let username = this.username;
        let password = this.password;
        let params = token ? { token } : { username, password };
        if (this.$route.query.code) {
          params = { code: this.$route.query.code };
        }

        this.$store
          .dispatch("auth/login", params)
          .then(() => {
            let state = this.$route.query.state == "0" ? false : this.$route.query.state;
            let forwardPath = this.$route.query.redirect || state || "/";
            this.$router.push(forwardPath);
          })
          .catch((err) => {
            this.error = err;
            this.getGCPUrl();
          })
          .finally(() => {
            this.authenticating = false;
          });
      }
    },
    getToken(name) {
      let b = document.cookie.match("(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)");
      return b ? b.pop() : "";
    }
  },
  created() {
    if (this.$route.query.expired) {
      this.error = "Session has expired.";
    } else if (this.$route.query.error) {
      this.error = this.$route.query.error_description || "Session has expired.";
    }

    if (this.$route.query.code) {
      this.login();
    } else {
      this.getGCPUrl();
      let token = this.getToken("jwt_token");
      this.upsSession.username = this.getToken("CNETDirectGlobalLogin");
      if (token) {
        this.upsSession.token = token;
      }
    }
  }
};
</script>

<style scoped>
.textDivider {
  width: 90%;
}

.textDivider div {
  padding: 0 10px 0 10px;
}
</style>
